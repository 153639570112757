import React from "react";
import { graphql } from "gatsby";
import SalesList from "../components/SalesList";

const HelicoptersByModel = props => (
  <SalesList {...props} settings={{ heading: "Helicopter Sales" }} />
);

export default HelicoptersByModel;

export const query = graphql`
  query HelicoptersByModelQuery($model: String!) {
    allForSale: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { model: { eq: $model } }
        fileAbsolutePath: { regex: "/(helicopters)/[^/]+[.]md$/" }
      }
    ) {
      edges {
        node {
          ...helicopterFrontmatter
        }
      }
    }

    allMarkdownRemark(
      sort: { fields: [frontmatter___position], order: ASC }
      filter: { frontmatter: { showcase: { eq: true } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            make
          }
        }
      }
    }
  }
`;
